import type {
  DefaultPageStoryblok,
  InsightPageStoryblok,
} from '@/types/component-types-sb'

export const useHeaderTheme = () => {
  // Initialize the state with default values
  const state = useState('headerTheme', () => ({
    background: 'black',
    position: 'absolute',
  }))

  const setState = (pageBlok: DefaultPageStoryblok | InsightPageStoryblok) => {
    state.value.background = pageBlok.header_background || 'white'
    state.value.position = pageBlok.header_position || 'static'
  }

  return {
    headerTheme: state,
    setHeaderTheme: setState,
  }
}
